<template>
  <div class="o-layout">
    <div class="c-input o-layout__item u-1-of-3-at-small">
      <input class="c-input__field c-input__field-file js-input-file" type="file" @change="onFileChange"
             id="logoUrl" accept=".png,.jpg, .svg" :data-multiple-caption="'{count} ' + $t('uploadFilesSelected')">
      <label class="c-input__field c-input__field-file-as-button c-button c-button--tertiary" for="logoUrl">{{$t('uploadFile')}}</label>
      <!--<span class="c-input__field-file-name js-input-file-name"></span>-->
    </div>
    <div class="o-layout__item u-2-of-3-at-small">
      <div class="c-input c-input__uploaded-image">
        <img :src="value" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "imageInput",
    props: ['value'],
    methods: {
      onFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit('input', e.target.result);
        };
        reader.readAsDataURL(files[0]);
      },
    },
  }
</script>

<style scoped>
</style>
