<template>
  <div>
    <div class="o-layout" v-if="discipline.rotationType === 'mixed'">
      <div class="o-layout__item">
        <sceSelect v-model="config.type" label="type" :options="$options.types"/>
      </div>
    </div>

    <div class="o-layout" v-if="config.type === 'regular'">
      <div class="o-layout__item">
        <sceNumberField v-model.number="config.generalTime" :name="config.name+'-warmup.general'" label="session.warmup.general.duration"
                        min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120"
                        :class="{'is-invalid': errors.has('warmup.general')}" />
      </div>
      <div class="o-layout__item">
        <sceCheckBox v-model="config.generalTimeOverlap" :name="config.name+'-warmup.generalTimeOverlap'"
                     label="session.generalTimeOverlap"/>
      </div>
      <div class="o-layout__item">
        <sceNumberField v-model.number="config.rotationTime" :name="config.name+'-warmup.apparatus'" label="session.warmup.apparatus.duration"
                        min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120"
                        :class="{'is-invalid': errors.has('warmup.apparatus')}" />
      </div>
      <div class="o-layout__item">
        <sceCheckBox v-model="config.rotationTimeOverlap" :name="config.name+'-warmup.rotationTimeOverlap'"
                     label="session.rotationTimeOverlap"/>
      </div>
      <div class="o-layout__item">
        <sceNumberField v-model.number="config.prepTime" :name="config.name+'-warmup.touch'" label="session.warmup.touch.duration"
                        min="0" max="1200" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:1200"
                        :class="{'is-invalid': errors.has('warmup.touch')}" />
      </div>
      <div class="o-layout__item">
        <sceCheckBox v-model.number="config.prepPerCompetitor" :name="config.name+'-warmup.touch.perCompetitor'"
                     label="session.warmup.touch.perCompetitor"/>
      </div>
    </div>
    <div class="o-layout" v-if="config.type === 'scheduled'">
      <div class="o-layout__item">
        <table class="o-table o-table--fixed c-table--results">
          <thead>
          <tr>
            <th>{{ $t('label') }}</th>
            <th>{{ $t('session.warmup.zoneTime') }}</th>
            <th>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(zone, index) of config.zones" :key="index">
            <td>
              <input v-model="zone.label" class="c-input__field" type="text" />
            </td>
            <td>
              <input v-model.number="zone.time" class="c-input__field" type="number" min="0" max="100" step="1" />
            </td>
            <td>
              <button class="c-button c-button--ghost c-button--small c-button--icon"
                      v-on:click.prevent="removeZone(index)">
                <svg role="img" class="c-button__icon">
                  <use xlink:href="images/svg-symbols.svg#close"></use>
                </svg>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <sceButton class="c-button--secondary" label="add" icon="svg-symbols.svg#plus-icon"
                         v-on:click="addZone()" />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import options from 'client/lib/options';
  import merge from "lodash/merge";
  import cloneDeep from "lodash/cloneDeep";

  export default {
    name: "warmUpConfig",
    props: ['value', 'discipline'],
    ...options.warmup,
    data: function() {
      return {
        config: {
          type: 'regular',
          generalTime: undefined,
          generalTimeOverlap: false,
          rotationTime: undefined,
          rotationTimeOverlap: false,
          prepTime: undefined,
          prepPerCompetitor: false,
          exerciseTime: undefined,
          zones: [],
        }
      }
    },
    created: function() {
      console.log(this.value)
      this.config = merge(this.config, cloneDeep(this.value))
    },
    watch: {
      config: {
        deep: true,
        handler: function() {
          this.$emit('input', this.config)
        },
      },
    },
    methods: {
      addZone: function() {
        this.config.zones.push({
          label: this.$t('label'),
          time: 0,
        })
      },
      removeZone: function(index) {
        this.config.zones.splice(index, 1)
      },
    },
  }
</script>

<style scoped>
</style>
