<template>
  <div class="c-search-bar">
    <sceTextField v-if="! config.nosearch" v-model="output.searchString"
                  class="c-input--inline c-input--icon c-input--icon-search" id="searchString"/>
    <template v-if="! config.nosort">
      <sceSelect v-model="output.sortOrder" :options="sortFieldOptions()"
                 class="c-input--inline u-margin-right-small" />
      <sceButton icon="svg-symbols.svg#sort-asc" v-on:click="output.sortDir = 'asc'"
                 :class="{'c-button--tertiary': output.sortDir === 'asc', 'c-button--secondary': output.sortDir !== 'asc'}" />
      <sceButton icon="svg-symbols.svg#sort-desc" v-on:click="output.sortDir = 'desc'" class="u-margin-right-large"
                 :class="{'c-button--tertiary': output.sortDir === 'desc', 'c-button--secondary': output.sortDir !== 'desc'}" />
    </template>

    <template>
      <slot name="action"></slot>
    </template>
  </div>
</template>

<script>
import clone from 'lodash/clone'

export default {
  name: "searchBar",
  props: ['value', 'config'],
  data: function() {
    return {
      output: {
        searchString: '',
        sortOrder: '',
        sortDir: 'asc',
      }
    }
  },
  created: function() {
    this.output = clone(this.value)
  },
  watch: {
    output: {
      deep: true,
      handler: function() {
        this.$emit('input', clone(this.output))
      }
    }
  },
  methods: {
    sortFieldOptions: function() {
      return this.config.sortFields.map(field => ({
        id: field,
        name: this.$t(field),
      }))
    }
  },
}
</script>

<style scoped>

</style>
