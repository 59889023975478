<template>
  <div class="c-main">
    <main class="c-datagrid">
      <div class="c-datagrid__header" v-if="! config.noSearchBar">
        <search-bar v-model="search" :config="getSearchConfig()">
          <template slot="action">
            <slot name="gridAdd"></slot>
          </template>
        </search-bar>
      </div>

      <template v-if="pageData.length > 0">
        <div class="u-margin-bottom-large">
          <div class="c-list__header">
            <slot name="gridHeader" />
          </div>

          <div class="c-list__body">
            <template v-if="data">
              <div v-for="(element,e_index) in pageData" :key="element.id"
                   class="o-layout c-list__row u-padding-horizontal-small"
                   :class="{'is-moved': element.id === movedId}">
                <slot name="gridData" v-bind:element="element" :index="e_index" />
                <div class="c-list__row-controls" v-if="config.orderButtons">
                  <button class="c-button c-button--icon c-button--small c-button--primary"
                          v-on:click="switchOrder(element.id, false)">
                    <svg role="img" class="c-button__icon c-list__row-control-down" >
                      <use xlink:href="images/svg-symbols.svg#arrow-right"></use>
                    </svg>
                  </button>
                  <button class="c-button c-button--icon c-button--small c-button--primary"
                          v-on:click="switchOrder(element.id, true)">
                    <svg role="img" class="c-button__icon c-list__row-control-up" >
                      <use xlink:href="images/svg-symbols.svg#arrow-right"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else >
        <div class="o-layout u-margin-bottom-large">
          <div class="o-layout__item">
            {{$t('dataGrid.noresults')}}
          </div>
        </div>
      </template>
    </main>

    <footer v-if="! config.nopaging" class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height">
      <div class="o-layout o-layout--center-space-around">
        <div class="o-layout__item u-1-of-4-at-small">
          <div class="o-layout o-layout--center-space-around">
            <a v-on:click.prevent="pagePrevious()" href="#!"
              class="c-button c-button--primary c-button--large c-button--icon"
              :class="{'c-button--disabled': page === 0}">
              <svg role="img" class="c-button__icon u-rotate-180">
                <use xlink:href="images/svg-symbols.svg#arrow-right"></use>
              </svg>
              <span class="c-button__label">{{$t("panel.previous")}}</span>
            </a>
          </div>
        </div>

        <div class="o-layout__item u-2-of-4-at-small">
          <div class="o-layout o-layout--center-space-around">
            <p class="u-margin-vertical-medium">{{page+1}} / {{pages}}</p>
          </div>
        </div>

        <div class="o-layout__item u-1-of-4-at-small">
          <div class="o-layout o-layout--center-space-around">
            <a v-on:click.prevent="pageNext()" href="#!"
              class="c-button c-button--primary c-button--large c-button--icon"
              :class="{'c-button--disabled': page + 1 >= pages}">
              <span class="c-button__label">{{$t("panel.next")}}</span>
              <svg role="img" class="c-button__icon">
                <use xlink:href="images/svg-symbols.svg#arrow-right"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import {max, min, ceil} from 'mathjs'
  import numeral from 'numeral';

  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import orderBy from 'lodash/orderBy'
  import slice from 'lodash/slice'

  export default {
    name: "dataGrid",
    props: ['config', 'data'],
    components: {
      searchBar: require('./searchBar.vue').default,
    },
    data: function() {
      return {
        movedId: null,
        page: 0,
        pages: 1,
        search: {
          searchString: '',
          sortOrder: '',
          sortDir: 'asc',
        },
        filteredData: [],
        pageData: [],
      }
    },
    created: function() {
      if (this.config.sortFields) {
        this.search.sortOrder = this.config.defaultSortField ? this.config.defaultSortField : this.config.sortFields[0]
      }
      this.filterData()
    },
    watch: {
      search: function() {
        this.filterData(false)
      },
      data: function() {
        this.filterData(false)
      },
    },
    methods: {
      getSearchConfig: function() {
        return {
          nosearch: ! this.config.searchFields?.length,
          nosort: ! this.config.sortFields?.length,
          sortFields: this.config.sortFields,
        }
      },
      filterData: function(resetPage = true) {
        let filteredData = this.data
        if (this.search.searchString.length) {
          const string = this.search.searchString.toLowerCase();
          filteredData = filter(filteredData, item => {
            const searchFields = this.config.searchFields;
            const match = find(searchFields, searchField => {
              let value = item[searchField];
              if (!value) {
                return false;
              }
              if (Array.isArray(value)) {
                const res = find(value, valItem => {
                  return valItem && valItem.toLowerCase().indexOf(string) >= 0;
                });
                return res !== undefined;
              } else if (typeof value == 'number') {
                value = numeral(value).format('0')
              }
              return value && value.toLowerCase().indexOf(string) >= 0;
            });

            return match !== undefined;
          })
        }

        if (this.search.sortOrder) {
          filteredData = orderBy(filteredData, this.search.sortOrder, this.search.sortDir)
        }

        this.filteredData = filteredData

        this.pages = max(ceil(this.filteredData.length / 20), 1);
        if (resetPage) {
          this.page = 0;
        } else {
          this.page = min(this.page, max(0, this.pages-1));
        }
        this.updatePage();
      },
      pageNext: function() {
        if (this.page + 1 === this.pages) {
          return;
        }
        this.page += 1;
        this.updatePage();
      },
      pagePrevious: function() {
        if (this.page === 0) {
          return;
        }
        this.page -= 1;
        this.updatePage();
      },
      updatePage: function() {
        if (this.config.nopaging) {
          this.pageData = this.filteredData;
        } else {
          this.pageData = slice(this.filteredData, this.page * 20, (this.page + 1) * 20);
        }
      },
      switchOrder: function(id, up) {
          this.movedId = id;
          this.$emit('switchOrder', {id: id, up: up});
          setTimeout(() => { this.movedId = null;}, 3000);
      }
    }
  }
</script>
