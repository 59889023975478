<template>
  <div class="o-layout">
    <div class="o-layout__item u-1-of-1">
      <h3 class="c-title c-title--page-section">{{$t('planning.session.timingSettings')}}</h3>
    </div>
    <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
      <div class="o-layout">
        <div class="o-layout__item">
          <sceNumberField v-model.number="config.judgeMeetingOffset" name="judgeMeetingOffset" label="session.judgeMeetingOffset"
                          min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120" />
        </div>
        <div class="o-layout__item">
          <sceNumberField v-model.number="config.marchinPrepTime" name="marchinPrepTime" label="session.marchinPrepTime"
                          min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120" />
        </div>
        <div class="o-layout__item">
          <sceNumberField v-model.number="config.marchinTime" name="marchinTime" label="session.marchinTime"
                          min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120" />
        </div>
        <div class="o-layout__item">
          <sceNumberField v-model.number="config.rotationTime" name="rotationTime" label="session.rotationTime"
                          min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120" />
        </div>
        <div class="o-layout__item">
          <sceNumberField v-model.number="config.ceremonyPrepTime" name="ceremonyPrepTime" label="session.ceremonyPrepTime"
                          min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120" />
        </div>
        <div class="o-layout__item">
          <sceNumberField v-model.number="config.ceremonyTime" name="ceremonyTime" label="session.ceremonyTime"
                          min="0" max="120" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:120" />
        </div>
      </div>
    </div>
    <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
      <div class="o-layout">
        <div class="o-layout__item">
          <div class="c-input">
            <label class="c-input__label" for="dayStartTime">{{$t('session.dayStartTime')}}</label>
            <input v-model="config.dayStartTime" class="c-input__field" name="dayStartTime" type="time" id="dayStartTime" />
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceNumberField v-model="config.sets" name="sets" :label="setsLabel" min="1" max="20" step="1"
                          v-validate data-rules="required|numeric|min_value:1|max_value:20" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceNumberField v-model.number="config.rotations" name="rotations" label="session.rotations"
                          min="1" max="30" step="1" v-validate data-rules="required|numeric|min_value:1|max_value:30" />
        </div>
        <div class="o-layout__item" v-if="discipline.rotationType === 'rotation'">
          <sceSelect v-model="config.rotationType" name="rotationType"
                     label="session.rotationType" :options="$options.rotationTypes"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('rotationType')}" />
        </div>
        <div class="o-layout__item" >
          <sceSelect v-model="config.blockTimeType" name="blockTimeType"
                     label="planning.blockTimeType" :options="$options.blockTimeType"
                     v-validate data-vv-rules="required" />
        </div>
        <template v-if="config.blockTimeType !== 'fixed'">
          <div class="o-layout__item">
            <sceNumberField v-model.number="config.exerciseTime" name="exerciseTime" label="session.schedule.exerciseTime"
                            min="10" max="1800" step="1" v-validate data-rules="required|numeric|min_value:10|max_value:1800" />
          </div>
          <div class="o-layout__item">
            <sceNumberField v-model.number="config.judgeTime" name="judgeTime" label="session.schedule.judgeTime"
                            min="0" max="300" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:300" />
          </div>
        </template>
        <template v-else>
          <div class="o-layout__item">
            <sceNumberField v-model.number="config.blockTime" name="blockTime" label="planning.blockTime"
                            min="0" max="100" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:100" />
          </div>
          <div class="o-layout__item">
            <sceNumberField v-model.number="config.blockTimeFirst" name="blockTimeFirst" label="planning.blockTimeFirst"
                            min="0" max="100" step="1" v-validate data-rules="required|numeric|min_value:0|max_value:100" />
          </div>
        </template>
      </div>
    </div>
    <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-large">
      <h4 class="c-title c-title--page-section">{{ $t('config.warmupConfigurations')}}</h4>

      <sceTabs v-model="warmupTabs">
        <sceTab v-for="(tab, i) in warmupTabs" :key="'warmupConfig-'+i"
                :id="'warmupConfig-'+i" :label="tab.name">
          <warmupConfig v-model="config.warmupConfigs[i]" :discipline="discipline"/>
          <sceButton label="delete" v-on:click="removeWarmupConfig(config.warmupConfigs[i])"/>
        </sceTab>
        <sceTab id="warmupConfig-add" :index="9999" :label="$t('new')">
          <sceTextField v-model="newWarmupConfigName" label="name" />
          <sceButton class="c-button--primary c-button--large" :class="{'c-button--disabled': ! newWarmupConfigName}"
                     label="add" v-on:click="addWarmUpConfig()"/>
        </sceTab>
      </sceTabs>
    </div>

    <div class="o-layout__item">
      <h4 class="c-title c-title--page-section">{{$t('planning.session.customSessionTimes')}}</h4>

      <table class="o-table o-table--fixed c-table--results">
        <thead>
          <tr>
            <th>{{ $t('label') }}</th>
            <th>{{ $t('time') }} ({{ $t('min') }})</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(timeItem, ind) of config.customSessionTimes" :key="ind">
            <td>
              <input v-model="timeItem.label" class="c-input__field" type="text" />
            </td>
            <td>
              <input v-model="timeItem.time" class="c-input__field" type="number" min="-1000" max="1000" />
            </td>
            <td>
              <select v-model="timeItem.position"  class="c-input__field c-input__field-select" >
                <option value="before" >{{ $t('planning.customSessionTime.before') }}</option>
                <option value="after" >{{ $t('planning.customSessionTime.after') }}</option>
              </select>
            </td>
            <td>
              <button class="c-button c-button--ghost c-button--small c-button--icon"
                      v-on:click.prevent="removeTimeTableItem(ind)">
                <svg role="img" class="c-button__icon">
                  <use xlink:href="images/svg-symbols.svg#close"></use>
                </svg>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <sceButton class="c-button--secondary" label="add" icon="svg-symbols.svg#plus-icon"
                         v-on:click="addTimeTableItem()" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="o-layout__item u-margin-top-medium">
      <h3 class="c-title c-title--page-section">{{$t('planning.session.orderSettings')}}</h3>

      <div v-if="orderType === 'mixed'" class="o-layout u-margin-bottom-medium">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="config.orderPrinciple" name="orderPrinciple"
                     label="planning.orderPrinciple" :options="$options.orderPrinciples"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('orderPrinciple')}" />

        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input--extra-info">
            {{getOrderPrincipleDescription(config.orderPrinciple)}}
          </div>
        </div>

        <template v-if="config.orderPrinciple === 'random'">
          <div class="o-layout__item u-1-of-2-at-small">
            <header class="c-list__header">
              <div class="c-list__row u-padding-horizontal-small">
                <h4 class="c-list__header-title">
                  {{$t('planning.groupingOptions')}}
                </h4>
              </div>
            </header>
            <div class="c-list__body c-list__body-shadow">
              <draggable v-model="config.grouping" :group="{name: 'level', pull: ['level'], put: ['level']}"
                         @start="drag=true" @end="drag=false" @change="groupingChange()"
                         class="c-session__list" :class="{'has-no-child': config.grouping.length === 0}">
                <div v-for="(item,index) in config.grouping" type="selected" :key="'selected-' + item.type"
                     class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-small">
                  <div class="c-session-block__bib o-layout__item u-1-of-12">
                    <div class="">
                      {{index+1}}
                    </div>
                  </div>
                  <div class="c-session-block__participant o-layout__item u-9-of-12">
                    <div class="name">{{$t('sessionOrder.' + item.type)}}</div>
                  </div>
                  <div class="c-session-block__remove-button" :id="'remove-part-button-' + index" style="top: 0">
                    <button v-on:click.prevent="removeOrderOption(item, index)"
                            class="c-button c-button--ghost c-button--small c-button--icon">
                      <svg role="img" class="c-button__icon">
                        <use xlink:href="images/svg-symbols.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <header class="c-list__header">
              <div class="c-list__row u-padding-horizontal-small">
                <h4 class="c-list__header-title">
                  {{$t('sessionOptions.addOptions')}}
                </h4>
              </div>
            </header>
            <div class="c-list__body c-list__body-shadow">
              <draggable v-model="groupingUnselected" :group="{name: 'level', pull: ['level'], put: ['level']}"
                         class="c-session__list">
                <div v-for="item in groupingUnselected" type="free" :key="'unselected-' + item.type"
                     class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-small">
                  <div class="c-session-block__participant o-layout__item u-1-of-1">
                    <div class="name">{{$t('sessionOrder.' + item.type)}}</div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </template>

        <div class="o-layout__item u-1-of-1" v-if="config.orderPrinciple === 'random'">
          <div class="o-layout">
            <div class="o-layout__item">
              <sceCheckBox v-model="config.clubApart" label="planning.groupOption.clubApart" name="clubApart" />
            </div>
            <div class="o-layout__item">
              <sceCheckBox v-model="config.repeatOrder" label="planning.groupOption.repeatOrder" name="repeatOrder" />
            </div>
            <div class="o-layout__item">
              <sceNumberField v-model.number="config.partDistance" name="partDistance"
                              label="planning.groupOption.partDistance" min="0" max="10" step="1" />
            </div>
          </div>
        </div>
        <div class="o-layout__item u-1-of-1" v-if="config.orderPrinciple !== 'random'">
          <sceCheckBox v-model="config.repeatPerCategory" label="planning.rotate.perCategory" name="perCategory" />
        </div>
        <div class="o-layout__item u-1-of-1">
          <sceCheckBox v-model="config.mixedHideExerciseTime" label="planning.order.ideExerciseTime" name="ideExerciseTime" />
        </div>
      </div>
      <div v-else class="o-layout u-margin-bottom-medium">
        <div class="o-layout__item u-1-of-2-at-small">
          <header class="c-list__header">
            <div class="c-list__row u-padding-horizontal-small">
              <h4 class="c-list__header-title">
                {{$t('planning.groupingOptions')}}
              </h4>
            </div>
          </header>
          <div class="c-list__body c-list__body-shadow">
            <draggable v-model="config.grouping" :group="{name: 'level', pull: ['level'], put: ['level']}"
                       @start="drag=true" @end="drag=false" @change="groupingChange()"
                       class="c-session__list" :class="{'has-no-child': config.grouping.length === 0}">
              <div v-for="(item,index) in config.grouping" type="selected" :key="'selected-' + item.type"
                   class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-small">
                <div class="c-session-block__bib o-layout__item u-1-of-12">
                  <div class="">
                    {{index+1}}
                  </div>
                </div>
                <div class="c-session-block__participant o-layout__item u-9-of-12">
                  <div class="name">{{$t('sessionOrder.' + item.type)}}</div>
                </div>
                <div class="c-session-block__remove-button" :id="'remove-part-button-' + index" style="top: 0">
                  <button v-on:click.prevent="removeOrderOption(item, index)"
                          class="c-button c-button--ghost c-button--small c-button--icon">
                    <svg role="img" class="c-button__icon">
                      <use xlink:href="images/svg-symbols.svg#close"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <header class="c-list__header">
            <div class="c-list__row u-padding-horizontal-small">
              <h4 class="c-list__header-title">
                {{$t('sessionOptions.addOptions')}}
              </h4>
            </div>
          </header>
          <div class="c-list__body c-list__body-shadow">
            <draggable v-model="groupingUnselected" :group="{name: 'level', pull: ['level'], put: ['level']}"
                       class="c-session__list">
              <div v-for="item in groupingUnselected" type="free" :key="'unselected-' + item.type"
                   class="c-list__row c-list__row--draggable o-layout o-layout--flush u-padding-horizontal-small">
                <div class="c-session-block__participant o-layout__item u-1-of-1">
                  <div class="name">{{$t('sessionOrder.' + item.type)}}</div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="o-layout__item u-1-of-1 u-margin-top-medium" >
          <div class="o-layout">
            <div class="o-layout__item" v-if="showGroupClubOption()">
              <sceSelect v-model="config.groupClubs" label="planning.groupOption.clubDetail" :options="$options.groupByClub" />
            </div>

            <div class="o-layout__item" v-if="showGroupCategoryOption()">
              <sceSelect v-model="config.groupByCategory" label="planning.groupOption.categoryGrouping" :options="$options.groupByCategory" />
            </div>
            <div class="o-layout__item">
              <sceCheckBox v-model="config.checkOverlaps" label="planning.order.checkOverlaps" name="checkOverlaps" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import merge from 'lodash/merge'

import options from 'client/lib/options'

import draggable from 'vuedraggable'

  export default {
    name: 'planningConfig',
    components: {
      warmupConfig: require('./warmupConfig.vue').default,
      draggable,
    },
    orderOptions: [{
      type: 'club-stick',
      orderTypes: ['block'],
    },{
      type: 'category-stick',
      orderTypes: ['block', 'mixed'],
    },{
      type: 'region-stick',
      orderTypes: ['block', 'mixed'],
    },{
      type: 'team-stick',
      orderTypes: ['block'],
    },{
      type: 'exercise-stick',
      orderTypes: ['mixed'],
    }],
    ...options.session,
    ...options.planning,
    props: ['value', 'discipline'],
    data: function () {
      return {
        orderType: 'block',
        groupingUnselected: [],
        subDivisionUnselected: [],
        setLabel: 'panel',
        setsLabel: 'panels',
        newWarmupConfigName: null,
        warmupTabs: [],
        config: {
          clubApart: false,
          customSessionTimes: [],
          dayStartTime: '09:00',
          judgeMeetingOffset: null,
          marchinPrepTime: null,
          marchinTime: null,
          rotationTime: null,
          ceremonyPrepTime: null,
          ceremonyTime: null,
          sets: 1,
          rotations: 1,
          rotationType: 'fixed',
          blockTimeType: 'byCount',
          blockTime: 20,
          blockTimeFirst: 0,
          exerciseTime: null,
          judgeTime: null,
          orderPrinciple: 'random',
          groupClubs: 'groupClubs',
          grouping: [],
          partDistance: 0,
          repeatOrder: false,
          repeatPerCategory: true,
          warmupConfigs: [],
          mixedHideExerciseTime: false,
        }
      }
    },
    created: function () {
      this.initConfig()
    },
    watch: {
      config: {
        deep: true,
        handler: function() {
          this.$emit('input', this.config)
        },
      },
    },
    methods: {
      initConfig: function() {
        if (this.discipline.rotationType === 'mixed') {
          this.orderType = 'mixed';
        }

        this.config = merge(this.config, cloneDeep(this.value))

        if (! this.config.grouping) {
          if (this.config.orderPreferences) {
            this.config.grouping = this.config.orderPreferences
            delete(this.config.orderPreferences)
          } else {
            this.config.grouping = []
          }
        }

        this.groupingUnselected = filter(this.$options.orderOptions, option => {
          return includes(option.orderTypes, this.orderType) &&
            ! find(this.config.grouping, item => item.type === option.type)});

        this.setsLabel = this.discipline.rotationType === 'rotation' ? 'sets' : 'panels';
        this.warmupTabs = this.getWarmupTabs();

        if (this.config.repeatOrder === undefined) {
          this.config.repeatOrder = false
        }
        if (this.config.clubApart === undefined) {
          this.config.clubApart = false
        }
      },
      groupingChange: function() {
        this.$emit('input', this.config)
      },
      showGroupClubOption: function() {
        if ('block' !== this.orderType) {
          return false
        }

        let result = false
        this.config.grouping.forEach(g => {
          result = (g.type === 'club-stick')
        })
        return result
      },
      showGroupCategoryOption: function() {
        if ('mixed' === this.orderType) {
          return false
        }

        return this.config.grouping[0]?.type === 'category-stick'
      },
      getWarmupTabs: function(index = 0) {
        const warmups = map(this.config.warmupConfigs, (c, i) => {
          return {
            id: 'warmupConfig-' + i,
            label: c.name,
            isActive: i === index,
          }
        });
        return [...warmups, {id: 'warmupConfig-add', label: this.$t('new'), isActive: warmups.length === 0}];
      },
      removeTimeTableItem: function(i) {
        this.config.customSessionTimes.splice(i, 1)
      },
      addTimeTableItem: function() {
        this.config.customSessionTimes.push({
          label: this.$t('label'),
          time: 0,
          position: 'before',
        })
      },
      addWarmUpConfig: function() {
        const index = this.config.warmupConfigs.length
        this.$set(this.config.warmupConfigs, index, {
          name: this.newWarmupConfigName,
        })
        this.warmupTabs = this.getWarmupTabs(index)
        this.$nextTick(() => {
          this.newWarmupConfigName = null
        })
      },
      removeWarmupConfig: function(warmupConfig) {
        this.config.warmupConfigs = filter(this.config.warmupConfigs, c => c.name !== warmupConfig.name);
        this.warmupTabs = this.getWarmupTabs();
      },
      removeOrderOption: function(option) {
        this.config.grouping = filter(this.config.grouping, item => item.type !== option.type);
        this.groupingUnselected.push(option);
      },
      getOrderPrincipleDescription: function(principle) {
        if (! principle) return ''
        return this.$t('planning.orderPrinciple.description.' + principle)
      },
    },
  }
</script>

<style scoped>

</style>
