<template>
  <div class="c-modal-mask" @click="close" v-show="shown" transition="modal">
    <div class="c-modal-container" @click.stop>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'modal',
    props: ['shown'],
    methods: {
      close: function() {
        this.$emit('closed');
      }
    },
    updated: function() {
        if (this.shown) {
            document.querySelector('html').style.overflow = "hidden";
        } else {
            document.querySelector('html').style.overflow = "scroll";
        }
    }
  };
</script>
